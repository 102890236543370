// Router
import { useNavigate } from "@/lib/router"
import { Routes } from "@/constants/routes"

// Translate
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// Dates
import { DateTime, ms } from "@/lib/dates"
import { useCurrentDate } from "@/hooks/useCurrentDate"

// General UI
import { Card, CardWrapper } from "@/components/Card"
import { Button } from "@/components/Button"
import { Heading } from "@/components/Typography"
import { Select } from "@/components/Select"

// Icons
import { FiDownload } from "@/lib/icons"

function useLastTwelveMonths() {
	const now = useCurrentDate(ms("1h"))
	const months = []

	for (let i = 0; i < 12; i++) {
		const date = new Date(now)
		date.setMonth(now.getMonth() - i)

		// Format as YYYY-MM
		const monthStr = date.toISOString().slice(0, 7)
		months.push(monthStr)
	}

	return months
}

/**
 * IntroHeader
 * @returns
 */
interface IntroHeaderProps {
	displayDownloadButton?: boolean
	fullName?: string | null
	endTime: DateTime
	handlePdfDownload?: Function
	totalProjectCount: number
	totalInvestmentValue: string
}
export function IntroHeader({
	displayDownloadButton = true,
	fullName,
	endTime,
	handlePdfDownload = () => {},
	totalProjectCount,
	totalInvestmentValue,
}: IntroHeaderProps) {
	// Translate
	const t = useTrans(["investments", "dashboard"])
	const { formatCurrency } = useLang()

	// Router
	const navigate = useNavigate()

	// Dates
	const monthOptions = useLastTwelveMonths().map((date) => ({
		key: date,
		value: DateTime.fromFormat(date, "yyyy-MM").toLocaleString({
			month: "long",
			year: "numeric",
		}),
	}))

	// Template
	return (
		<CardWrapper>
			<Card>
				<div className="flex gap-2">
					<Heading as="h2" styleAs="h5">
						{t("investments:investments.monthly_overview.intro", {
							fullName,
							month: endTime.toLocaleString({
								month: "long",
							}),
						})}
					</Heading>

					<Select
						value={endTime.toFormat("yyyy-MM").toString()}
						onChangeValue={(year) => {
							console.log(year)
							year = year || String(DateTime.local().year)
							navigate(
								Routes.InvestmentsProjectsMonthlyOverview.replace(
									":date",
									year,
								),
							)
						}}
						label={
							<>
								{endTime.toLocaleString({
									month: "long",
									year: "numeric",
								})}
							</>
						}
						options={monthOptions}
						variant="transparent"
					/>

					{/** Download button */}
					{displayDownloadButton === true ? (
						<div className="ml-auto">
							<Button
								onClick={() => {
									handlePdfDownload()
								}}
								aria-label="Download"
								size="small"
							>
								<span className="lg:hidden">
									{t(
										"investments:investments.fiscal.download.mobile_text",
									)}
								</span>
								<span className="hidden lg:inline">
									{t(
										"investments:investments.fiscal.download",
									)}
								</span>
								<FiDownload className="ml-2 h-4 w-4" />
							</Button>
						</div>
					) : null}
				</div>

				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:mt-6">
					{/** Project Total */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"dashboard:dashboard.stats.platform_stats.blocks.total_projects",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{totalProjectCount}
						</dd>
					</div>

					{/** Current total value */}
					<div className="col-span-1 rounded-md bg-gray-50 px-4 py-3">
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"investments:investments.fiscal.hero.returns_today.title",
							)}
						</dt>
						<dd className="mt-1 flex text-sm text-gray-900">
							{formatCurrency(
								totalInvestmentValue
									? parseFloat(totalInvestmentValue)
									: 0,
							)}
						</dd>
					</div>
				</div>
			</Card>
		</CardWrapper>
	)
}
