// TODO: This should reflect src/pages folder structure
export enum Routes {
	// Generic
	Home = "/",

	// Auth / Login related pages
	Register = "/account/register",
	RegisterConfirm = "/account/confirm",
	Login = "/account/login",
	RecoverPassword = "/account/recover",
	ResetPassword = "/account/reset",

	// Statistics
	StatisticsPlatform = "/stats/platform",
	StatisticsPlatformEndex = "/stats/platform/endex",
	StatisticsPlatformSingleProject = "/stats/project",

	// Investments
	Investments = "/investments",
	InvestmentsProjects = "/investments/projects",
	InvestmentsProjectsDashboard = "/investments/projects/dashboard",
	InvestmentsProjectsDashboardPaginated = "/investments/projects/dashboard/page/:page",
	InvestmentsProjectsCurrentOverview = "/investments/projects/dashboard/current-overview",
	InvestmentsProjectsFiscalOverview = "/investments/projects/dashboard/fiscal-overview",
	InvestmentsProjectsProduction = "/investments/projects/dashboard/production",
	InvestmentsProjectsMonthlyOverview = "/investments/projects/dashboard/monthly-overview/:date",
	ProvisionalInvestments = "/investments/projects/dashboard/provisional",
	Communications = "/investments/projects/dashboard/messages",
	InvestmentsProjectsPayments = "/investments/projects/dashboard/payments",

	// Finance B2B
	Finance = "/finance",
	FinanceProjects = "/finance/projects",
	FinanceInterestPayments = "/finance/interest-payments",
	FinanceSolarInterestTariff = "/finance/solar-interest-tariff",
	FinanceBondsLoan = "/finance/bonds-loan",

	// Investor profile
	InvestorProfile = "/investor",
	InvestorIdentity = "/investor/identity",
	InvestorTest = "/investor/test",
	InvestorRisk = "/investor/risk",
	InvestorFunds = "/investor/funds",

	// Settings
	AppSettings = "/settings/profile/app",
	SettingsProfilePersonalDetails = "/settings/profile",
	SettingsProfileCompanyDetails = "/settings/profile/company-details",
	SettingsProfileBankDetails = "/settings/profile/bank-details",
	SettingsProfileChangePassword = "/settings/profile/change-password",
	SettingsProfileNotifications = "/settings/profile/notifications",

	// Security
	SettingsProfileSecurity = "/settings/profile/security",
	SettingsProfileSecurityVerify = "/settings/profile/security/verify",
	SettingsProfileSecurityRegenerateBackupCodes = "/settings/profile/security/regenerate-backup-codes",
	Recent = "/settings/profile/activity",

	// Admin
	Info = "/settings/app/info",
}
